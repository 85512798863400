import client from "../transport/contentful";
import merge from 'lodash/merge';

export const createCollection = async (value) => {
    return await client.getSpace(process.env.SPACE_ID)
        .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
        .then((environment) => environment.createEntry('collections', {
            fields: {
                name: {
                'en-US': value
                }
            }
        }))
        .then(entry => entry.publish())
        .then((entry) => {
            return entry;
        })
        .catch(console.error)
};


export const getCollection = id => {
      const data = client.getSpace(`${process.env.SPACE_ID}`)
      .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
      .then((environment) => environment.getEntry(id))
      .then((entry) => {
        return entry;
      })
      .catch(console.error);
      return data;
};


// export const updateCollection = async (name, collectionId) => {
//     return await client.getSpace(process.env.SPACE_ID)
//         .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
//         .then((environment) => environment.getEntry(collectionId))
//         .then((entry) => {
//             entry.fields.name['en-US'] = name;
//             return entry.update();
//         })
//         .then(entry => entry.publish())
//         .then((entry) => {
//             return entry;
//         })
//         .catch(console.error)
// };

export const updateCollection = async ({ collectionId, values }) => {
    return await client.getSpace(`${process.env.SPACE_ID}`)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(collectionId))
    .then((entry) => {
      entry.fields = values;
      return entry.update();
    })
    .then(entry => entry.publish())
    .then((entry) => {
        return entry;
    })
    .catch(console.error)
  };


export const deleteCollection = async (collectionId) => {
    return await client.getSpace(process.env.SPACE_ID)
        .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
        .then((environment) => environment.getEntry(collectionId))
        .then((entry) => entry.unpublish())
        .then((entry) => {
            return entry;
        })
        .then((entry) => entry.delete())
        .then(() => collectionId)
        .catch(console.error)
};

