import client from "../transport/contentful";
// const fs = require('fs');
// import {fs} from 'fs';

export const getAssets = () => {
  // console.log(process.env.SPACE_ID)

  const getData = async({ allentries = [], skip = 0 } = {}) => client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getAssets({
    skip: skip,
    limit: 1000 // max allowed
  }))
  .then((assets) => {
    const entries = [...allentries, ...assets.items]
    assets.items = entries;
    // console.log(entries);
    // console.log(assets);
    return entries.length < assets.total ? getData({allentries: entries, skip: skip + 1000}): assets;
  })
  .catch(console.error);

  // console.log(getData({allentries: [], skip: 0}));

  // const data = client.getSpace(`${process.env.SPACE_ID}`)
  // .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  // .then((environment) => environment.getAssets({
  //   limit: 1000 // max allowed
  // }))
  // .then((assets) => {
  //   console.log(assets);
  //   return assets;
  // })
  // .catch(console.error);


  return getData({allentries: [], skip: 0});
}

// export const createAsset = ({image, title}) => {
//   const name = title ? title : image.name;
//   return client.getSpace(`${process.env.SPACE_ID}`)
//   .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
//   .then((environment) => {
//     return environment.createUpload({
//       file: image,
//       contentType: image.type,
//       fileName: image.name 
//     })
//     .then(async (upload) => {
//       const asset = await environment.createAsset({
//         fields: {
//           title: { 'en-US': name },
//           file: {
//             'en-US': {
//               fileName: image.name,
//               contentType: image.type,
//               uploadFrom: {
//                 sys: {
//                   type: 'Link',
//                   linkType: 'Upload',
//                   id: upload.sys.id
//                 }
//               }
//             }
//           }
//         }
//       });
//       const asset_1 = await asset.processForLocale('en-US', { processingCheckWait: 500, processingCheckRetries: 10 });
//       const asset_2 = await asset_1.publish();
//       return asset_2;
//     })
//     .catch(console.error)
//   })
//   return data;
// }

export const createAsset = ({image, title}) => {
  const name = title ? title : image.name;
  return client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => {
    return environment.createAssetFromFiles({
      fields: {
        title: { 'en-US': name },
        file: {
          'en-US': {
            file: image,
            contentType: image.type,
            fileName: image.name 
          }
        }
      }
    })
  })
  .then((asset) => asset.processForLocale('en-US', { processingCheckWait: 500, processingCheckRetries: 10 }))
  .then((asset) => asset.publish())
  .catch(console.error);
}

export const deleteAsset = async (assetId) => {
  return await client.getSpace(process.env.SPACE_ID)
      .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
      .then((environment) => environment.getAsset(assetId))
      .then((asset) => asset.unpublish())
      .then((asset) => {
          return asset;
      })
      .then((asset) => asset.delete())
      .then(() => assetId)
      .catch(console.error)
};

export const updateAsset = async ({assetId, title}) => {
  // console.log(assetId);
  // console.log(title);
  // return {};
  return await client.getSpace(process.env.SPACE_ID)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getAsset(assetId))
  .then((asset) => {
    asset.fields.title['en-US'] = title;
    return asset.update();
  })
  .then(asset=> asset.publish())
  .then((asset) => {
      return asset;
  })
  .catch(console.error)
};