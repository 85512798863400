import { createAction } from 'redux-actions';
import * as API from '../../../api/Product';

export const createProduct = createAction(
  'CREATE_PRODUCT',
  async input => {
    const response = await API.createProduct(input);
    return response;
  }
)

export const updateProduct = createAction(
  'UPDATE_PRODUCT',
  async(input) => {
    // console.log(input);
    const response = await API.updateProduct(input);
    return response;
  }
)

export const getProduct = createAction(
  'GET_PRODUCT',
  async(params) => {
    const response = await API.getProduct(params);
    // console.log(response);
    return response;
  }
)