import client from "../transport/contentful";
import resolveResponse from 'contentful-resolve-response';
// var resolveResponse = require('contentful-resolve-response');


export const createProduct = (values) => {
  const input = {fields: {}};
  Object.entries(values).forEach(([key, value]) => {
    input.fields[key] = { 'en-US': value}
  });
  // console.log(input);

  const data = client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.createEntry('product', input))
  .then(entry => entry.publish())
    .then((entry) => {
        return entry;
    })
  .catch(console.error)

  return data;
}

export const getProduct = id => {
  const data = client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getEntry(id))
  .then((entry) => {
    return entry;
  })
  .catch(console.error);
  return data;
};

export const updateProduct = async ({productId, values}) => {
  return await client.getSpace(`${process.env.SPACE_ID}`)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(productId))
    .then((entry) => {
      entry.fields = values;
      return entry.update();
    })
    .then(entry => entry.publish())
    .then((entry) => {
        return entry;
    })
    .catch(console.error)
};
