import { createAction } from 'redux-actions';
import * as API from '../../../api/AboutUs';

export const updateAboutUs = createAction(
  'UPDATE_ABOUT_US',
  async(input) => {
    // console.log(input)
    const response = await API.updateAboutUs(input);
    // console.log(response)
    return response;
  }
) 