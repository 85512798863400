import { createAction } from 'redux-actions';
import * as API from '../../../api/TradeProgram';

export const updateTradeProgramPage = createAction(
  'UPDATE_TRADE_PROGRAM_PAGE',
  async(input) => {
    // console.log(input)
    const response = await API.updateTradeProgram(input);
    // console.log(response)
    return response;
  }
) 