import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const useActions = actionCreators => {
  const dispatch = useDispatch();
  return bindActionCreators(actionCreators, dispatch);
};

export default useActions;


// const useActions = actionCreators => {
//   const dispatch = useDispatch();
//   // const bindActions = async
//   const bindAction = () => {
//     return bindActionCreators(actionCreators, dispatch);
//   };
  
//   useEffect(() => { bindAction() },[]);
// };

// export default useActions;