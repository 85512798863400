import { createAction } from 'redux-actions';
import * as API from '../../../api/Page';

export const getPages = createAction(
  'GET_PAGES',
  async (params) => {
    return null;
  }
);

export const getPage = createAction(
  'GET_PAGE',
    async input => {
      const response = await API.getPage(input);
      return response;
});

export const addCollectionPage = createAction(
  'UPDATE_PAGE',
    async (pageId, collection) => {
      const response = await API.addCollectionPage(pageId, collection);
      return response;
});

export const getCollectionsPage = createAction(
  "GET_COLLECTIONS_PAGE", async (input) => {
    const response = await API.getCollectionsPage(input);
    return response;
  }
);

export const removeCollectionPage = createAction(
  'REMOVE_COLLECTION_PAGE',
    async (pageId, collectionId) => {
      const response = await API.removeCollectionPage(pageId, collectionId);
      return response;
});

export const updateCollectionsPage = createAction(
  "UPDATE_PAGE_COLLECTIONS",
  async (pageId, collections) => {
    const response = await API.updateCollectionsPage(pageId, collections);
    return response;
  }
);
