import client from "../transport/contentful";
import resolveResponse from 'contentful-resolve-response';

export const getPage = ({ id }) => {
  const data = client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getEntry(id))
  .then((entry) => {
    return entry;
  })
  .catch(console.error);
  return data;
}

// https://contentful.github.io/contentful-management.js/contentful-management/latest/Entry.html#.update
// Add collection to collections field.
// Success: returns the updated page.
// Params: 
  // pageId: id of the page to be updated
  // collection: collection to be added in collections field
export const addCollectionPage = async (pageId, collection) => {
  return await client.getSpace(process.env.SPACE_ID)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(pageId))
    .then((entry) => {
      if(!entry.fields.collections){
        entry.fields.collections = {
            'en-US': [{
              sys: {
                type: 'Link',
                linkType: 'Entry',
                id: collection.sys.id
              }
            }]
        }
      }else{
        entry.fields.collections['en-US'].push({
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: collection.sys.id
          }
        })
      }
      return entry.update()
    })
    .then(entry => entry.publish())
    .catch(console.error)
};

// Remove collection from collections field.
// Success: returns the updated page.
// Params: 
  // pageId: id of the page to be updated
  // collectionId: collection to be removed from collections field
export const removeCollectionPage = async (pageId, collectionId) => {
  return await client.getSpace(process.env.SPACE_ID)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(pageId))
    .then((entry) => {
      entry.fields.collections['en-US'] = entry.fields.collections['en-US'].filter(el => el.sys.id !== collectionId);
      return entry.update();
    })
    .then(entry => entry.publish())
    .catch(console.error)
}

export const getCollectionsPage = (pageId) => {
  const data = client
    .getSpace(`${process.env.SPACE_ID}`)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(pageId))
    .then((entry) => {
      return entry;
    })
    .catch(console.error);
  return data;
};

export const updateCollectionsPage = async (pageId, collections) => {
  return await client
    .getSpace(process.env.SPACE_ID)
    .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
    .then((environment) => environment.getEntry(pageId))
    .then((entry) => {
      entry.fields.collections["en-US"] = [];
      for(let collection of collections){
        entry.fields.collections["en-US"].push({
          sys: {
            type: "Link",
            linkType: "Entry",
            id: collection.id
          }
        })
      }
      return entry.update();
    })
    .then((entry) => entry.publish())
    .catch(console.error);
};
