import { createAction } from 'redux-actions';
import * as API from '../../../api/Home';

export const updateHome = createAction(
  'UPDATE_HOME',
  async(input) => {
    // console.log(input)
    const response = await API.updateHome(input);
    // console.log(response)
    return response;
  }
)