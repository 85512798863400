import client from "../transport/contentful";
import { isEmpty } from "lodash";

export const updateHome = async ({ homeId, products, activateSales, showSfSalePage, showLaSalePage, title, subtitle, background, bannerLinkType }) => {
  return await client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getEntry(homeId))
  .then((entry) => {
    if(!entry.fields.products){
      entry.fields.products = {'en-US': []};
    } 
    entry.fields.products['en-US'] = products;
    entry.fields['activateSales'] = {'en-US': activateSales};
    entry.fields['showSfSalePage'] = {'en-US': showSfSalePage};
    entry.fields['showLaSalePage'] = {'en-US': showLaSalePage}
    entry.fields['title'] = {'en-US': title}
    entry.fields['subtitle'] = {'en-US': subtitle}
    if (!isEmpty(background)) {
      entry.fields['background'] = {'en-US': background}
    } else {
      entry.fields['background'] = {}
    }
    entry.fields['bannerLinkType'] = {'en-US': bannerLinkType}
    return entry.update();
  })
  .then(entry => entry.publish())
  .then((entry) => {
    return entry;
  })
  .catch(console.error)
};