import client from "../transport/contentful";
import axios from 'axios';

// export const getEntries1 = () => {
//   const data = client.getSpace(`${process.env.SPACE_ID}`)
//   .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
//   .then((environment) => environment.getEntries({
//     limit: 1000 // max allowed
//   }))
//   .then((entry) => {
//     console.log(entry);
//     return entry;
//   })
//   .catch(console.error);
//   return data;
// };

export const getEntries = () => {
  const data = client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then(async (environment) => {
    let entries = {};
    const fetch1 = await environment.getEntries({
      limit: 1000
    });
    const fetch2 = await environment.getEntries({
      limit: 1000,
      skip: 1000
    });
    entries = { ...fetch1, items: [...fetch1.items, ...fetch2.items]};
    console.log(entries);
    return entries;
  }).catch(console.error);
  return data;
}

export const postDeploy = async() => {
  return await axios.post('https://api.netlify.com/build_hooks/5e7d9b0a9da3882d0d69400e')
}