import { createAction } from 'redux-actions';
import * as API from '../../../api/Contact';

export const updateContactPage = createAction(
  'UPDATE_CONTACT_PAGE',
  async(input) => {
    // console.log(input)
    const response = await API.updateContact(input);
    // console.log(response)
    return response;
  }
) 