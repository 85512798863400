export * from './pages';
export * from './assets';
export * from './products';
export * from './collections';
export * from './home';
export * from './contact';
export * from './aboutUs';
export * from './tradeProgram';

import * as API from '../../../api';
import { createAction } from 'redux-actions';
// export default actions;

export const getEntries = createAction(
  'GET_ENTRIES',
  async(params) => {
    const response = await API.getEntries();
    return response;
  }
)

export const logoutUser = createAction('LOGOUT');
