import client from "../transport/contentful";

export const updateContact = async ({ entryId, values }) => {
  return await client.getSpace(`${process.env.SPACE_ID}`)
  .then((space) => space.getEnvironment(process.env.ENVIRONMENT_ID))
  .then((environment) => environment.getEntry(entryId))
  .then((entry) => {
    // console.log(entry)
    // console.log(values)
    entry.fields = values;
    return entry.update();
  })
  .then(entry => entry.publish())
  .then((entry) => {
    return entry;
  })
  .catch(console.error)
};