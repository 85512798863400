import { createAction } from 'redux-actions';
import * as API from '../../../api/Asset';

export const getAssets = createAction(
  'GET_ASSETS',
  async(params) => {
    const response = await API.getAssets();
    return response;
  }
)

export const createAsset = createAction(
  'CREATE_ASSET',
  async input => {
    return await API.createAsset(input);
    // const response = await API.createAsset(input);
    // console.log(response);
    // return response;
  }
)

export const deleteAsset = createAction(
  'DELETE_ASSET',
  async(assetId) => {
      const response = await API.deleteAsset(assetId);
      return response;
  }
);

export const updateAsset = createAction(
  'UPDATE_ASSET',
  async(params) => {
    const response = await API.updateAsset(params);
    return response;
});