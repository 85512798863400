import { createAction } from 'redux-actions';
import * as API from '../../../api/Collection';
 
export const createCollection = createAction(
    'CREATE_COLLECTION',
    async(name) => {
        const response = await API.createCollection(name);
        return response;
    }
);

// export const updateCollection = createAction(
//     'UPDATE_COLLECTION',
//     async(name, collectionId) => {
//         const response = await API.updateCollection(name, collectionId);
//         return response;
//     }
// );

export const deleteCollection = createAction(
    'DELETE_COLLECTION',
    async(collectionId) => {
        const response = await API.deleteCollection(collectionId);
        return response;
    }
);

export const getCollection = createAction(
    'GET_COLLECTION',
    async(params) => {
      const response = await API.getCollection(params);
      return response;
    }
  )
  
  export const updateCollection = createAction(
    'UPDATE_COLLECTION',
    async(input) => {
      // console.log(input)
      const response = await API.updateCollection(input);
      // console.log(response)
      return response;
    }
  )
